@use "./_variable.scss" as v;
@use "./_mixin.scss" as mix;
.sp{
    &__ttl{
        display: none;
    }
    &__link{
        display: none;
    }
}
@media screen and (max-width : v.$tbw){

    .sp{
        &__ttl{
            @include mix.bg();
            background: url('./images/logo_pc.png') no-repeat;
            width: 42.66vw;
            height: 24.26vw;
            position: fixed;
            top: -1.6vw;
            left: -5vw;
            display: block;
            z-index: 2;

        }
        &__link{
            @include mix.bg();
            background: url('./images/link_top.svg') no-repeat;
            width: 38.66vw;
            height: 38.66vw;
            display: block;
            z-index: 10;
            position: fixed;
            top: 110vw;
            right: -4px;
            transition: all 0.5s;

            &.btmposition{
                transition: all 0.5s;
                // transform: translate(0, 20%);
            }
        }
        &__link2{
            @include mix.bg();
            background: url('./images/icon.svg') no-repeat;
            width: 38.66vw;
            height: 38.66vw;
            display: block;
            z-index: 10;
            position: fixed;
            top: 110vw;
            right: -4px;
            transition: all 0.5s;

            &.btmposition{
                transition: all 0.5s;
                // transform: translate(0, 20%);
            }
        }
        &__link3{
            @include mix.bg();
            background: url('./images/badge_1216.png') no-repeat;
            width: 38.66vw;
            height: 38.66vw;
            display: block;
            z-index: 10;
            position: fixed;
            top: 110vw;
            right: -4px;
            transition: all 0.5s;

            &.btmposition{
                transition: all 0.5s;
                // transform: translate(0, 20%);
            }
        }

    }
        
}



