@use "./_variable.scss" as v;


@mixin bg(){
    background-size: 100% auto;
    display: block;
    white-space: nowrap;
    text-indent: -9999px;
    background-repeat: no-repeat;
}
@mixin fz($size) {
	font-size: calc($size / 16) + rem;
}

@keyframes fadeInAnime{
    0% {
        opacity: 0;
    }
    80%{
        opacity: 1;

    }
    100% {
        opacity: 1;
    }
}