@use "./_variable.scss" as v;
@use "./_mixin.scss" as mix;

.pccontents{
    width: 100%;
    height: 100vh;
    position: relative;
    &__inner{
        background: url('./images/pcbg.jpg') no-repeat;
        background-position: center center;
        background-attachment: fixed;
    
        /* 表示するコンテナの大きさに基づいて、背景画像を調整 */
        background-size: cover;
    
        position: fixed;
        width: 100%;
        height: 100vh;
        overflow: hidden;
    }
}

.pc{
    &__ttl{
        @include mix.bg();
        background: url('./images/logo_pc.png') no-repeat;
        width: 387px;
        height: 214px;
        position: absolute;
        top: -14px;
        left: -30px;
    }
    &__qr{
        @include mix.bg();
        background: url('./images/qr.png') no-repeat;
        width: 130px;
        height: 175px;
        position: absolute;
        bottom: 35px;
        right: 293px;
    }
    &__link{
        @include mix.bg();
        background: url('./images/link_top.svg') no-repeat;
        width: 220px;
        height: 220px;
        position: absolute;
        bottom: 34px;
        right: 34px;
    }
    &__link2{
        @include mix.bg();
        background: url('./images/icon.svg') no-repeat;
        width: 220px;
        height: 220px;
        position: absolute;
        bottom: 34px;
        right: 34px;
    }
    &__link3{
        @include mix.bg();
        background: url('./images/badge_1216.png') no-repeat;
        width: 220px;
        height: 220px;
        position: absolute;
        bottom: 34px;
        right: 34px;
    }
}

.spcon{
    position: relative;
    width: 375px;
    height: auto;
    margin: 0 auto;
}